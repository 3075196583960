import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import UrlForm from './UrlForm';
import UrlList from './UrlList';
import API_BASE_URL from '../config';

function UrlShortener() {
  const [urls, setUrls] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage] = useState(8);
  const [totalUrls, setTotalUrls] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState('');


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const pageFromUrl = parseInt(queryParams.get('page')) || 1;
    setCurrentPage(pageFromUrl);
  }, [location.search]);

  useEffect(() => {
    console.log('currentPage changed:', currentPage);
    fetchUrls(); // Ejecuta la búsqueda al cargar la página o al cambiar de página
  }, [currentPage]);  // Solo se ejecuta cuando cambia la página
  
  const fetchUrls = async () => {
    try {
      const params = {
        page: currentPage,
      };

      console.log('Sending request with params:', params);  // Depuración: Ver los parámetros antes de la solicitud

      const response = await axios.get(`${API_BASE_URL}/urls`, { params });

      console.log('Response from API:', response.data);  // Depuración: Ver la respuesta del servidor

      setUrls(response.data.urls);
      setTotalUrls(response.data.totalUrls);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching URLs:', error);
    }
  };

  const handleShortenUrl = async (fullUrl) => {
    try {
      await axios.post(`${API_BASE_URL}/shorten`, { fullUrl });
      fetchUrls();
    } catch (error) {
      console.error('Error shortening URL:', error);
    }
  };

  const handleEditUrl = async (id, updatedFields) => {
    try {
      const response = await axios.put(`${API_BASE_URL}/${id}`, updatedFields);
      setUrls(urls.map(url => url._id === id ? response.data : url));
    } catch (error) {
      console.error('Error editing URL:', error);
    }
  };

  const handleDeleteUrl = async (id) => {
    try {
      await axios.delete(`${API_BASE_URL}/${id}`);
      setUrls(urls.filter(url => url._id !== id));
    } catch (error) {
      console.error('Error deleting URL:', error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('isLoggedIn');
    navigate('/login');
  };

  const indexOfLastUrl = currentPage * itemsPerPage;
  const indexOfFirstUrl = indexOfLastUrl - itemsPerPage;
  const currentUrls = urls;  // Ya tenemos los URLs correctos filtrados

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      navigate(`?page=${currentPage - 1}`);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      navigate(`?page=${currentPage + 1}`);
    }
  };

  const goToPage = (page) => {
    navigate(`?page=${page}`);
  };
  const handleSearch = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/urls`, {
        params: {
          page: currentPage,
          shortUrl: searchQuery,  // Se pasa el query de búsqueda
        },
      });
  
      setUrls(response.data.urls);
      setTotalUrls(response.data.totalUrls);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching URLs:', error);
    }
  };
  
  return (
    <div className="UrlShortener">
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <button className='volver-button' onClick={() => navigate('/page/app')} style={{ marginRight: '10px' }}>
          Back
        </button>
        <button className='logout-button' onClick={handleLogout}>
          Log out
        </button>
      </div>

      <h1 style={{ marginTop: 50 }}>Links Shortener</h1>

      <UrlForm onShortenUrl={handleShortenUrl} />
      <div style={{ marginBottom: '20px' }}>
  <input
    type="text"
    placeholder="Search by short URL"
    value={searchQuery}
    onChange={(e) => setSearchQuery(e.target.value)}  // Actualiza el estado con el valor del campo
    style={{ marginRight: '10px' }}
  />
  <button onClick={handleSearch}>
    Search
  </button>
</div>

      <UrlList 
        urls={currentUrls} 
        onEditUrl={handleEditUrl} 
        onDeleteUrl={handleDeleteUrl} 
      />

      <div className="row">
        <div className="col-sm-12 col-md-5">
          <div className="dataTables_info" role="status" aria-live="polite">
            Showing {indexOfFirstUrl + 1} to {indexOfLastUrl} of {totalUrls} entries
          </div>
        </div>

        <div className="col-sm-12 col-md-7">
          <div className="dataTables_paginate paging_simple_numbers">
            <ul className="pagination">
              <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`} onClick={goToPreviousPage}>
                <a href="#" className="page-link">Previous</a>
              </li>

              {[...Array(totalPages)].map((_, i) => (
                <li
                  key={i + 1}
                  className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}
                  onClick={() => goToPage(i + 1)}
                >
                  <a href="#" className="page-link">{i + 1}</a>
                </li>
              ))}

              <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`} onClick={goToNextPage}>
                <a href="#" className="page-link">Next</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UrlShortener;
